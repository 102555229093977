/* eslint-disable consistent-return */
import React, {
  useCallback, useState, useMemo, useEffect, useRef,
} from 'react';
import { Route } from 'react-router-dom';
import { Menu, Dropdown } from 'antd';
import classNames from 'classnames';

import ActionIcons from '../../../../../wiredComponents/ActionIcons';

import Events from '../../../../../lib/events';
import { apiUrls } from '../../../../../api/constants';

import EventWrapper from '../../../../../components/EventWrapper';
import WidgetLoader from '../../../../../components/WidgetLoader';
import Notification from '../../../../../components/Notification';

import { UiRoutes, listIds } from '../../../../../lib/constants';
import dateFormatter from '../../../../../lib/dateFormatter';
import successMessages from '../../../../../lib/successMessages';
import errorMessages from '../../../../../lib/errorMessages';

import useRedirect from '../../../../../hooks/useRedirect';
import useCRUD from '../../../../../hooks/useCRUD';

import PatientPayment from '../PatientPayment';
import InsurancePayment from '../InsurancePayment';
import EditBatchModal from './editBatchForm';
import ErrorPopupOver from './ErrorPopOver';
import { parsedDecimalAmount } from '../RemittanceCommonParser';
import Icon from '../../../../../components/Icon';
import iconNames from '../../../../../lib/iconNames';
import ConfirmDialog from '../../../../../components/ConfirmDialog';
import WarningMessages from '../../../../../lib/warningMessages';

const billingStageErrorCodes = ['posterror', 'preposterror'];

const shouldShowDelete = (batchDetails) => (
  batchDetails?.remittanceBatchData?.stageMasterCode !== 'BillingStagePosted' && batchDetails?.remittanceBatchData?.batchType?.toLowerCase() !== 'electronic'
);

const BatchDetails = ({
  labels, batchDetails, currentPageNo, setCurrentPageNo, remittanceId, isElectronicBatch,
  toggleUploadBatchModal, isUpload, totalNoOfPages, expendRemittence, closeTab,
}) => {
  const {
    params, generatePath, replace,
  } = useRedirect();
  const [isEditBatchPopUpVisible, setIsEditBatchPopUpVisibility] = useState(false);
  const { remittanceBatchData } = batchDetails || {};
  const containerRef = useRef(null);

  const [
    insurancePaymentDetails,,,
    getInsurancePaymentDetails,
    clearInsurancePaymentDetails,
  ] = useCRUD({
    id: listIds.GET_REMITTANCE_PAYMENT_DETAILS_LIST,
    url: apiUrls.GET_REMITTANCE_PAYMENT_DETAILS_LIST,
    type: 'read',
  });

  const [
    remittnaceBatchDetails,,,
    getRemittanceBatchDetails,
    clearRemittanceBatchDetails,
  ] = useCRUD({
    id: listIds.MOVE_TO_READY,
    url: apiUrls.Move_BATCH_OUTBOX_TO_READY,
    type: 'update',
  });

  const [
    markAsBlankResponse, , blankLoading, setMarkAsBlank, clearMarkAsBlank] = useCRUD({
    id: listIds.REMITTANCE_BATCH_MARK_AS_BLANK,
    url: apiUrls.MARK_AS_BLANK,
    type: 'update',
  });

  const [
    unMarkAsBlankResponse, , unMarkAsBlankLoading, setUnMarkAsBlank, clearUnMarkAsBlank] = useCRUD({
    id: listIds.REMITTANCE_BATCH_UNMARK_AS_BLANK_PAGE,
    url: apiUrls.UNMARK_AS_BLANK_PAGE,
    type: 'update',
  });

  const [
    saveCompleteBatchResponse,,
    saveCompleteBatchLoading,
    saveCompleteBatch,
    clearSaveCompleteBatch,
  ] = useCRUD({
    id: listIds.SAVE_COMPLETE_BATCH,
    url: apiUrls.SAVE_COMPLETE_BATCH_NEW_API,
    type: 'create',
  });

  const [
    errorList,,,
    getBatchErrorList,
    clearBatchErrorList,
  ] = useCRUD({
    id: listIds.REMITTANCE_ERRORLIST,
    url: apiUrls.REMITTANCE_ERRORLIST,
    type: 'read',
  });

  const [
    deleteRemittanceCheckResponse,,
    deleteRemittanceCheckLoading,
    deleteRemittanceBatch,
    clearDeleteRemittanceBatch,
  ] = useCRUD({
    id: 'DELETE_REMITTANCE_CHECK',
    url: apiUrls.DELETE_REMITTANCE_CHECK,
    type: 'update',
  });

  useEffect(() => {
    if (remittanceId) {
      getInsurancePaymentDetails({ remittanceBatchId: remittanceId });
      Events.on(`refetch-remittance-payment-details${remittanceId}`, `refetch-remittance-payment-details${remittanceId}`, () => getInsurancePaymentDetails({ remittanceBatchId: remittanceId }));
    }
    return () => {
      clearInsurancePaymentDetails(true);
      Events.remove(`refetch-remittance-payment-details${remittanceId}`, `refetch-remittance-payment-details${remittanceId}`);
    };
  }, [remittanceId]);

  useEffect(() => {
    if (markAsBlankResponse) {
      Notification({ message: successMessages.MARK_AS_BLANK_PAGE_SUCCESSFULLY, success: true });
      clearMarkAsBlank();
      Events.trigger('refetchBatchDetails');
      Events.trigger(`refetch-remittance-payment-details${remittanceId}`);
    }
  }, [markAsBlankResponse]);

  useEffect(() => {
    if (unMarkAsBlankResponse) {
      Notification({ message: successMessages.UNMARK_AS_BLANK_PAGE_SUCCESSFULLY, success: true });
      clearUnMarkAsBlank();
      Events.trigger('refetchBatchDetails');
      Events.trigger(`refetch-remittance-payment-details${remittanceId}`);
    }
  }, [unMarkAsBlankResponse]);

  const navigateToInsurance = useCallback(() => {
    replace(
      generatePath(`${UiRoutes.claimsRemittanceNewInsurance}/searchClaim`, {
        ...params,
        remittancePaymentId: 0,
        pageNo: parseInt(currentPageNo, 10),
      }),
    );
  },
  [currentPageNo, generatePath, params, replace]);

  const navigateToEditInsurance = useCallback((remittancePaymentId) => replace(
    generatePath(`${UiRoutes.claimsRemittanceEditInsurance}`, {
      ...params,
      remittancePaymentId,
      insurancePaymentPage: 'editPaymentDetails',
    }),
  ),
  [generatePath, params, replace]);

  const navigateToEditPatient = useCallback((remittancePaymentId) => replace(
    generatePath(`${UiRoutes.claimsRemittanceEditPatient}`, {
      ...params,
      remittancePaymentId,
      patientPaymentPage: 'editPaymentDetails',
    }),
  ),
  [generatePath, params, replace]);

  const navigateToReady = useCallback(async () => {
    ConfirmDialog({
      okText: 'Confirm',
      title: 'Warning',
      content: 'Are you sure you want to move batch(s) from Outbox to ready queue?',
      icon: <Icon name="ExclamationCircleOutlined" />,
      onOk: async () => {
        try {
          // Call API to move batches to ready queue
          if (remittanceId) {
            await getRemittanceBatchDetails({ remittanceBatchId: remittanceId });
            Notification({ message: successMessages.MOVED_FROM_OUTBOX_TO_READY, success: true });
            replace(generatePath(UiRoutes.remittanceClaimsFilters, { ...params, errorTab: 'softEdits', claimFilter: 'outbox' }));
            clearRemittanceBatchDetails(true);
          }
        } catch (error) {
          console.error('Error moving batches to ready queue:', error);
        }
      },
    })();
  // eslint-disable-next-line max-len
  }, [remittanceId, getRemittanceBatchDetails, clearRemittanceBatchDetails, remittnaceBatchDetails, generatePath, replace]);

  useEffect(() => {
    clearRemittanceBatchDetails();
  }, [clearRemittanceBatchDetails]);

  const navigateToInsuranceBatchDetailError = useCallback((remittancePaymentId) => replace(
    generatePath(`${UiRoutes.claimsRemittanceErrorEditInsurance}/error`, {
      ...params, errorTab: 'payments', remittancePaymentId, insurancePaymentPage: 'editPaymentDetails',
    }),
  ),
  [generatePath, params, replace]);

  const navigateToPatient = useCallback(() => replace(
    generatePath(`${UiRoutes.claimsRemittanceNewPatient}/searchClaim`, {
      ...params,
      remittancePaymentId: 0,
      pageNo: parseInt(currentPageNo, 10),
    }),
  ),
  [currentPageNo, generatePath, params, replace]);

  const navigateToDetails = useCallback(() => {
    Events.trigger(`refetch-remittance-payment-details${remittanceId}`);
    replace(
      generatePath(UiRoutes.claimsRemittance, params),
    );
  }, [generatePath, params, remittanceId, replace]);

  const totalAmountDetails = useMemo(() => {
    const totalPostedAmount = { totalPatientAmount: 0, totalInuranceAmount: 0, totalAmount: 0 };
    insurancePaymentDetails?.result?.forEach((item) => {
      const transactionType = item?.type?.toLowerCase();
      if (transactionType?.includes('insurance')) {
        totalPostedAmount.totalInuranceAmount += item?.amount || 0;
      } else if (transactionType?.includes('patient')) {
        totalPostedAmount.totalPatientAmount += item?.amount || 0;
      }
    });
    totalPostedAmount.totalAmount = (totalPostedAmount?.totalPatientAmount
      + totalPostedAmount?.totalInuranceAmount).toFixed(2);
    return totalPostedAmount;
  }, [insurancePaymentDetails]);

  const isRemittanceErrorTab = useMemo(() => {
    const queueMasterCode = remittanceBatchData?.queueMasterCode?.toLowerCase();
    const stageCode = remittanceBatchData?.stageMasterCode?.toLowerCase();
    const stateCode = remittanceBatchData?.stateMasterCode?.toLowerCase();
    return (queueMasterCode === 'remittancequeue' && billingStageErrorCodes.includes(stageCode) && stateCode === 'billingstateerror');
  }, [remittanceBatchData]);

  const isRemittancePostedTab = useMemo(() => {
    const queueMasterCode = remittanceBatchData?.queueMasterCode?.toLowerCase();
    const stageCode = remittanceBatchData?.stageMasterCode?.toLowerCase();
    const stateCode = remittanceBatchData?.stateMasterCode?.toLowerCase();
    return (queueMasterCode === 'remittancequeue' && stageCode === 'billingstageposted' && stateCode === 'billingstateposted');
  }, [remittanceBatchData]);

  const markAsBlankPage = useCallback(() => {
    if (!isRemittanceErrorTab) {
      setMarkAsBlank({
        pageNo: currentPageNo,
      }, `/${remittanceId}`);
    }
  }, [isRemittanceErrorTab, setMarkAsBlank, currentPageNo, remittanceId]);

  const unMarkAsBlankPage = useCallback((financeId) => () => {
    setUnMarkAsBlank({}, `/${financeId}`);
  }, [setUnMarkAsBlank]);

  const navigateToRemittanceTable = useCallback(() => {
    if (params?.claimFilter === 'worklist') {
      replace(generatePath(UiRoutes.remittanceClaimsWorkList, { ...params, claimFilter: 'worklist', parentTab: 'suspense' }));
    } else {
      replace(generatePath(UiRoutes.remittanceClaimsFilters, { ...params }));
    }
  }, [replace, generatePath, params]);

  const handleDeleteRemittancePayment = useCallback((item) => {
    ConfirmDialog({
      onOk: (close) => {
        deleteRemittanceBatch({ financePaymentId: item?.financeId, deleteCheck: true });
        close();
      },
      okText: 'Ok',
      title: 'Warning',
      content: WarningMessages.WANT_TO_DELETE,
      icon: <Icon name={iconNames.exclamationCircleOutlined} />,
    })();
  }, [deleteRemittanceBatch]);

  const defaultRightClickMenu = useCallback((item) => () => (
    <Menu className="profile-menu-container">
      <Menu.Item key="delete transaction" onClick={() => handleDeleteRemittancePayment(item)}>
        <span>Delete Check</span>
      </Menu.Item>
    </Menu>
  ), [handleDeleteRemittancePayment]);

  const rightClickMenu = useCallback((item) => () => (
    <Menu className="profile-menu-container">
      {item?.type?.toLowerCase() === 'blank page' ? (
        <Menu.Item key="Edit Patient" onClick={unMarkAsBlankPage(item?.financeId)}>
          <span>Unmark as Blank Page</span>
        </Menu.Item>
      ) : (
        <div>
          {batchDetails?.remittanceBatchData?.batchType?.toLowerCase() !== 'electronic'
          && (
          <Menu.Item key="delete transaction" onClick={() => handleDeleteRemittancePayment(item)}>
            <span>Delete Check</span>
          </Menu.Item>
          )}
        </div>
      )}
    </Menu>
  ), [batchDetails, handleDeleteRemittancePayment, unMarkAsBlankPage]);

  const onNavigate = useCallback((item, isRemittanceErrorQueue) => () => {
    const transactionType = item?.type?.toLowerCase();
    const transactionStatus = item?.transactionStatus?.toLowerCase();
    const isTransactionCompleted = isRemittancePostedTab && transactionStatus === 'balanced' && !transactionType?.includes('suspense');

    if ((transactionStatus === 'error' || isRemittanceErrorQueue) && !transactionType?.includes('patient')) {
      return navigateToInsuranceBatchDetailError(item?.financeId);
    }
    if (!isTransactionCompleted && transactionType?.includes('insurance')) {
      return navigateToEditInsurance(item?.financeId);
    }
    if (!isTransactionCompleted && transactionType?.includes('patient')) {
      return navigateToEditPatient(item?.financeId);
    }
    return false;
  }, [isRemittancePostedTab, navigateToEditInsurance,
    navigateToEditPatient, navigateToInsuranceBatchDetailError]);

  const toggleEditBatchModal = useCallback(() => {
    setIsEditBatchPopUpVisibility(!isEditBatchPopUpVisible);
  }, [isEditBatchPopUpVisible]);

  const handleSaveCompleteBatch = useCallback(() => {
    if ((isRemittancePostedTab && batchDetails?.canSaveCompleteBatch) || (!isRemittancePostedTab)) {
      if (totalAmountDetails?.totalAmount !== remittanceBatchData?.totalBatchAmount?.toFixed(2)) {
        Notification({ message: errorMessages.TOTAL_AMOUNT_SHOULD_BE_EQUAL_TO_BATCH_AMOUNT });
        return;
      }
      saveCompleteBatch({
        data: {
          remittanceBatchId: remittanceId ? [parseInt(remittanceId, 10)] : [],
          isElectronic: remittanceBatchData?.batchType?.toLowerCase() !== 'manual',
        },
      });
    }
  }, [batchDetails, isRemittancePostedTab, remittanceBatchData, remittanceId,
    saveCompleteBatch, totalAmountDetails]);

  useEffect(() => {
    if (saveCompleteBatchResponse) {
      Notification({ message: successMessages.BATCH_COMPLETED_SUCCESSFULLY, success: true });
      clearSaveCompleteBatch();
      closeTab(params?.tabId, false);
      navigateToRemittanceTable();
    }
  }, [clearSaveCompleteBatch, closeTab, navigateToRemittanceTable,
    params, saveCompleteBatchResponse]);

  useEffect(() => {
    if (isRemittanceErrorTab) {
      getBatchErrorList({ remittanceBatchId: remittanceId });
      Events.on(`refetch-remittance-error-list${remittanceId}`, `refetch-remittance-error-list${remittanceId}`, () => getBatchErrorList({ remittanceBatchId: remittanceId }));
    }
    return () => {
      clearBatchErrorList(true);
      Events.remove(`refetch-remittance-error-list${remittanceId}`, `refetch-remittance-error-list${remittanceId}`, () => getBatchErrorList({ remittanceBatchId: remittanceId }));
    };
  }, [isRemittanceErrorTab]);

  useEffect(() => {
    if (deleteRemittanceCheckResponse) {
      Notification({ message: successMessages.CHECK_DELETED_SUCCESSFULLY, success: true });
      getInsurancePaymentDetails({ remittanceBatchId: remittanceId });
      Events.trigger('refetchBatchDetails');
      clearDeleteRemittanceBatch();
    }
  }, [deleteRemittanceCheckResponse, clearDeleteRemittanceBatch]);

  /**
   * Slow down scroll speed for resolving blank UI issue due to scroll
   */

  useEffect(() => {
    const container = containerRef.current;
    let isScrolling = false;
    let scrollTarget = container.scrollTop;

    const smoothScroll = () => {
      const currentScroll = container.scrollTop;
      const distance = scrollTarget - currentScroll;
      if (Math.abs(distance) > 1) {
        container.scrollTop = currentScroll + distance / 2;
        window?.requestAnimationFrame(smoothScroll);
      } else {
        isScrolling = false;
      }
    };

    const handleScroll = () => {
      if (!isScrolling) {
        isScrolling = true;
        scrollTarget = container.scrollTop;
        window?.requestAnimationFrame(smoothScroll);
      }
    };
    container.addEventListener('scroll', handleScroll);
    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="right-area posi-relative">
      {(blankLoading || saveCompleteBatchLoading || unMarkAsBlankLoading
      || deleteRemittanceCheckLoading) && <WidgetLoader />}
      <div ref={containerRef} className="pannel remittance-panel" id="post-claim-table">
        <div className="pannel-heading">
          {(isRemittanceErrorTab) && (
          <div className="row">
            <ErrorPopupOver errorList={errorList} />
          </div>
          )}
          <div className="row">
            <div className="key">
              {labels.get('labels.batch')}
            </div>
            <div className="value">
              {remittanceBatchData?.batchNumber}
            </div>
          </div>
          <div className="row">
            <div className="key">
              {labels.get('labels.checks')}
              :
            </div>
            <div className="value">
              {remittanceBatchData?.checkCount}
            </div>
          </div>
          <div className="row">
            <div className="key">
              {labels.get('labels.amount:')}
            </div>
            <div className="value">
              $
              {remittanceBatchData?.totalBatchAmount?.toFixed(2)}
            </div>
          </div>
          <div className="row">
            <div className="key">
              {labels.get('labels.posted')}
            </div>
            <div className="value">
              $
              {remittanceBatchData?.remittancePostedAmount?.toFixed(2)}
            </div>
          </div>
        </div>
        <Route exact path={UiRoutes.claimsRemittance}>
          <div className="panel-body ">
            <div className="flex justify-content-sp-bt">
              <p className="head-title">{remittanceBatchData?.insurancePayerName}</p>
              <div className="icon-wrap flex">
                <span className="edit-batch" title="Edit Batch Details" role="presentation" onClick={toggleEditBatchModal} />
                <ActionIcons print edit printProps={{ batchDetail: true, remittanceId }} />
              </div>
            </div>
            <div className="head-list">
              <div className="list-one">
                <div className="key">
                  {' '}
                  {labels.get('labels.depositDate')}
                </div>
                <div className="value">{remittanceBatchData?.depositDate?.dateString && dateFormatter(remittanceBatchData?.depositDate.dateString)}</div>
              </div>
              <div className="list-one">
                <div className="key">{labels.get('labels.assignedTo')}</div>
                <div className="value">{remittanceBatchData?.assignToUserName}</div>
              </div>
              <div className="list-one">
                <div className="key">{labels.get('labels.checkCount')}</div>
                <div className="value">{remittanceBatchData?.checkCount}</div>
              </div>
              <div className="list-one">
                <div className="key">{labels.get('labels.batchAmount')}</div>
                <div className="value">
                  $
                  {remittanceBatchData?.totalBatchAmount?.toFixed(2)}
                </div>
              </div>
              <div className="list-one">
                <div className="key">{labels.get('labels.batchIdentifier')}</div>
                <div className="value">
                  {remittanceBatchData?.batchIdentifier}
                  <span className="edit-icon" />
                </div>
              </div>
            </div>
            <div className="insurance-table-btn">
              <div className="table-in app-table">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        {labels.get('labels.insurancePayments')}
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        {parsedDecimalAmount(totalAmountDetails?.totalInuranceAmount || 0)}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {labels.get('labels.patientPayments')}
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        {parsedDecimalAmount(totalAmountDetails?.totalPatientAmount || 0)}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {labels.get('labels.total')}
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        {parsedDecimalAmount(
                          (totalAmountDetails?.totalPatientAmount || 0)
                      + (totalAmountDetails?.totalInuranceAmount || 0),
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="payment-btns mr-top-12">
                <button
                  type="button"
                  onClick={navigateToInsurance}
                  className={classNames('btn mr-rt-12', {
                    'btn-success': !isRemittanceErrorTab,
                    'cursor-not-allowed': isRemittanceErrorTab,
                  })}
                  disabled={isRemittanceErrorTab}
                >
                  {labels.get('buttons.insurancePayment')}
                </button>
                {/* disabled patient payment for now */}
                <button
                  type="button"
                  onClick={navigateToPatient}
                  className={classNames('btn', {
                    'btn-success': !isRemittanceErrorTab,
                    'cursor-not-allowed': isRemittanceErrorTab,
                    // 'cursor-not-allowed': true,
                  })}
                  disabled={isRemittanceErrorTab}
                  // disabled
                >
                  {labels.get('buttons.patientPayment')}
                </button>
                <div
                  className={classNames('table-discriptions', {
                    'cursor-pointer': !isRemittanceErrorTab,
                    'disable-table-descriptions cursor-not-allowed': isRemittanceErrorTab,
                  })}
                >
                  <EventWrapper type="p" onClick={markAsBlankPage}>
                    {labels.get('labels.markAsBlankPage')}
                  </EventWrapper>
                </div>
              </div>
            </div>
            <div className="collection-table-wrap mr-bt-16">
              <div className="collection-table">
                <div className="app-table">
                  <table>
                    <thead>
                      <tr>
                        <th>{labels.get('labels.pages')}</th>
                        <th>{labels.get('labels.checkNumber')}</th>
                        <th>{labels.get('labels.type')}</th>
                        <th>{labels.get('labels.status')}</th>
                        <th className="text-align-right">{labels.get('labels.amount')}</th>
                        <th>{' '}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {insurancePaymentDetails?.result?.map((item) => (
                        ((item?.type?.toLowerCase() === 'blank page'
                        || item?.transactionStatus?.toLowerCase() === 'error')
                        && !isRemittancePostedTab)
                          ? (
                            <Dropdown key={item?.financeDetailId} overlay={rightClickMenu(item)} placement="bottomLeft" trigger={['contextMenu']}>
                              <tr
                                onClick={item?.transactionStatus?.toLowerCase() === 'error' && onNavigate(item)}
                                className={classNames({
                                  'cursor-pointer': item?.transactionStatus?.toLowerCase() === 'error',
                                })}
                              >
                                <td>{item?.pages}</td>
                                <td>{item?.checkNumber}</td>
                                <td>{item?.type}</td>
                                <td>{item?.transactionStatus}</td>
                                <td className="text-align-right">{item?.type?.toLowerCase() === 'blank page' ? null : (item?.amount || 0).toFixed(2)}</td>
                              </tr>
                            </Dropdown>
                          )
                          : (
                            <Dropdown
                              key={item?.financeDetailId}
                              overlay={shouldShowDelete(batchDetails)
                                ? defaultRightClickMenu(item)
                                : <span />}
                              placement="bottomLeft"
                              trigger={['contextMenu']}
                            >
                              <tr
                                onClick={onNavigate(item, isRemittanceErrorTab)}
                                className="cursor-pointer"
                              >
                                <td>{item?.pages}</td>
                                <td>{item?.checkNumber}</td>
                                <td>{item?.type}</td>
                                <td>{item?.transactionStatus}</td>
                                <td className="text-align-right">{item?.type?.toLowerCase() === 'blank page' ? null : (item?.amount || 0).toFixed(2)}</td>
                              </tr>
                            </Dropdown>
                          )
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="payment-btns">
              <button type="button" className={classNames('btn btn-success mr-rt-12', { 'cursor-not-allowed disabled-btn': ((isRemittancePostedTab && !batchDetails?.canSaveCompleteBatch)) })} onClick={handleSaveCompleteBatch}>{labels.get('buttons.saveCompleteBatch')}</button>
              <button type="button" className="btn btn-primary mr-rt-12" onClick={navigateToRemittanceTable}>{labels.get('buttons.cancel')}</button>
              {remittanceBatchData.stateMasterCode === 'BillingStateOutBox' && (
              <button type="button" className="btn btn-success mr-rt-12" onClick={navigateToReady}>{labels.get('buttons.moveToReady')}</button>
              )}
            </div>
          </div>
        </Route>
        <Route path={[
          `${UiRoutes.claimsRemittanceNewInsurance}/searchClaim/:claimId/:id/:encounterId`,
          `${UiRoutes.claimsRemittanceNewInsurance}/searchClaim/:claimId/:id/:encounterId/:isRemittanceAddPayment`,
          `${UiRoutes.claimsRemittanceNewInsurance}/searchClaim/:claimId/:id`,
          `${UiRoutes.claimsRemittanceNewInsurance}/searchClaim`,
          UiRoutes.claimsRemittanceNewInsurance,
        ]}
        >
          <InsurancePayment
            onBack={navigateToDetails}
            labels={labels}
            totalNoOfPages={totalNoOfPages}
            currentPageNo={currentPageNo}
            remittanceBatchData={remittanceBatchData}
            setCurrentPageNo={setCurrentPageNo}
            isElectronicBatch={isElectronicBatch}
            path={UiRoutes.claimsRemittanceNewInsurance}
          />
        </Route>
        <Route path={[
          `${UiRoutes.claimsRemittanceEditInsurance}/searchClaim/:claimId/:id/:encounterId`,
          `${UiRoutes.claimsRemittanceEditInsurance}/searchClaim/:claimId/:id/:encounterId/:isRemittanceAddPayment`,
          `${UiRoutes.claimsRemittanceEditInsurance}/searchClaim/:claimId/:id/:identifier`,
          `${UiRoutes.claimsRemittanceEditInsurance}/searchClaim/:claimId/:id`,
          `${UiRoutes.claimsRemittanceEditInsurance}/searchClaim`,
          UiRoutes.claimsRemittanceEditInsurance,
        ]}
        >
          <InsurancePayment
            onBack={navigateToDetails}
            labels={labels}
            totalNoOfPages={totalNoOfPages}
            currentPageNo={currentPageNo}
            remittanceBatchData={remittanceBatchData}
            setCurrentPageNo={setCurrentPageNo}
            isElectronicBatch={isElectronicBatch}
            path={UiRoutes.claimsRemittanceEditInsurance}
          />
        </Route>
        <Route path={[
          `${UiRoutes.claimsRemittanceErrorEditInsurance}/searchClaim/:claimId/:id/:encounterId`,
          `${UiRoutes.claimsRemittanceErrorEditInsurance}/searchClaim/:claimId/:id/:encounterId/:isRemittanceAddPayment`,
          `${UiRoutes.claimsRemittanceErrorEditInsurance}/searchClaim/:claimId/:id/:identifier`,
          `${UiRoutes.claimsRemittanceErrorEditInsurance}/searchClaim/:claimId/:id`,
          `${UiRoutes.claimsRemittanceErrorEditInsurance}/searchClaim`,
          `${UiRoutes.claimsRemittanceErrorEditInsurance}/error`,
          UiRoutes.claimsRemittanceErrorEditInsurance,
        ]}
        >
          <InsurancePayment
            onBack={navigateToDetails}
            labels={labels}
            totalNoOfPages={totalNoOfPages}
            currentPageNo={currentPageNo}
            remittanceBatchData={remittanceBatchData}
            setCurrentPageNo={setCurrentPageNo}
            isElectronicBatch={isElectronicBatch}
            path={UiRoutes.claimsRemittanceErrorEditInsurance}
          />
        </Route>
        <Route path={[
          `${UiRoutes.claimsRemittanceNewPatient}/searchClaim/:PatientId`,
          `${UiRoutes.claimsRemittanceNewPatient}/searchClaim`,
          UiRoutes.claimsRemittanceNewPatient,
        ]}
        >
          <PatientPayment
            onBack={navigateToDetails}
            totalNoOfPages={totalNoOfPages}
            currentPageNo={currentPageNo}
            remittanceBatchData={remittanceBatchData}
            setCurrentPageNo={setCurrentPageNo}
            isElectronicBatch={isElectronicBatch}
            path={UiRoutes.claimsRemittanceNewPatient}
          />
        </Route>
        <Route path={[
          `${UiRoutes.claimsRemittanceEditPatient}/searchClaim/:PatientId`,
          `${UiRoutes.claimsRemittanceEditPatient}/searchClaim`,
          UiRoutes.claimsRemittanceEditPatient,
        ]}
        >
          <PatientPayment
            onBack={navigateToDetails}
            totalNoOfPages={totalNoOfPages}
            currentPageNo={currentPageNo}
            remittanceBatchData={remittanceBatchData}
            setCurrentPageNo={setCurrentPageNo}
            isElectronicBatch={isElectronicBatch}
            path={UiRoutes.claimsRemittanceEditPatient}
          />
        </Route>
        {isEditBatchPopUpVisible && (
          <EditBatchModal
            isVisible={isEditBatchPopUpVisible}
            toggleModal={toggleEditBatchModal}
            formData={remittanceBatchData}
          />
        )}
        {isUpload && (
          <EditBatchModal
            isVisible={isUpload}
            isUpload={isUpload}
            toggleModal={toggleUploadBatchModal}
            formData={remittanceBatchData}
          />
        )}
      </div>
      <button
        type="button"
        className="remittence-expend"
        onClick={() => expendRemittence()}
      >
        <span className="icon" />
      </button>
    </div>
  );
};
export default BatchDetails;
