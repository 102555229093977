/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable react/button-has-type */
import React, { useState, useCallback } from 'react';
import Diagnosis from './Diagnosis';
import Balance from './Balance';
import Cases from './Cases';
import Demographic from './Demographic';
import Authorization from './Authorization';
import History from './History';
import AnesthesiaBilling from './AnesthesiaBilling';
import TabContainer from '../../../components/TabsComponent';

const tabsList = [
  {
    label: 'Diagnosis',
    value: 'Diagnosis',
    toolTipTitle: 'Diagnosis',
    component: Diagnosis,
    key: '1',
    iconClassName: 'diagnosis-icon sprite-img-before',
  },
  {
    label: 'History',
    value: 'History',
    toolTipTitle: 'History',
    component: History,
    key: '2',
    iconClassName: 'history-icon sprite-img-before',
  },
  {
    label: 'Cases',
    value: 'Cases',
    toolTipTitle: 'Cases',
    component: Cases,
    key: '3',
    iconClassName: 'cases-icon sprite-img-before',
  },
  {
    label: 'Authorization',
    value: 'Authorization',
    toolTipTitle: 'Authorization',
    component: Authorization,
    key: '4',
    iconClassName: 'doctor-icon sprite-img-before',
  },
  {
    label: 'Balances',
    value: 'Balances',
    toolTipTitle: 'Balances',
    component: Balance,
    key: '5',
    iconClassName: 'balance-icon sprite-img-before',
  },
  {
    label: 'Demographic',
    value: 'Demographic',
    toolTipTitle: 'Demographic',
    component: Demographic,
    key: '6',
    iconClassName: 'demographic-icon sprite-img-before',
  },
  {
    label: 'Anesthesia billing',
    value: 'Anesthesia billing',
    toolTipTitle: 'Anesthesia billing',
    component: AnesthesiaBilling,
    key: '7',
    iconClassName: 'anesthesia-icon sprite-img-next-before',
  },
];

const EncounterTabs = ({
  setDiagnosesCodes,
  diagnosesCodes,
  anesthesia,
  handleParentDiagonsisChange,
  isCollectionCharges,
  setAnesthesia,
  encounterId,
  handleAnesthesiaAutoSave,
  loading,
  patientDateOfBirth,
  lineItemsData,
  claimFilter,
}) => {
  const [isExpanded, setIsExpanded] = useState(true); // Start expanded by default

  const toggleAccordion = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  return (
    <div className="left-tab-section">
      <div className="accordion-header">
        <p className="accordion-title">Encounter Overview</p>
        <div
          className={`accordion-btn ${isExpanded ? 'expanded' : ''}`}
          onClick={toggleAccordion}
          aria-expanded={isExpanded}
          aria-label={isExpanded ? 'Collapse panel' : 'Expand panel'}
          role="presentation"
        >
          <span className="arrow-icon" />
        </div>
      </div>
      <div className={`accordion-content ${isExpanded ? 'expanded' : ''}`}>
        <TabContainer
          tabs={tabsList}
          defaultActiveTab="Diagnosis"
          setDiagnosesCodes={setDiagnosesCodes}
          diagnosesCodes={diagnosesCodes}
          handleParentDiagonsisChange={handleParentDiagonsisChange}
          showToolTip
          anesthesia={anesthesia}
          setAnesthesia={setAnesthesia}
          encounterId={encounterId}
          handleAnesthesiaAutoSave={handleAnesthesiaAutoSave}
          loading={loading}
          patientDateOfBirth={patientDateOfBirth}
          isCollectionCharges={isCollectionCharges}
          lineItemsData={lineItemsData}
          isDisabled={isCollectionCharges && claimFilter === 'completed'}
        />
      </div>
    </div>
  );
};

export default EncounterTabs;
