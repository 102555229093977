import React, {
  useCallback, useState, useMemo, useEffect, useRef,
} from 'react';
import { Route } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { Form as AntdForm } from 'antd';
import moment from 'moment';
import omit from 'lodash/omit';

import useCRUD from '../../../../../hooks/useCRUD';
import withLabel from '../../../../../hoc/withLabel';
import { apiUrls } from '../../../../../api/constants';
import useRedirect from '../../../../../hooks/useRedirect';
import successMessages from '../../../../../lib/successMessages';
import {
  labelPaths, UiRoutes, listIds,
} from '../../../../../lib/constants';
import Events from '../../../../../lib/events';

import Form from '../../../../../components/Form';
import Button from '../../../../../components/Button';
import Search from '../../../../../components/Search';
import Loader from '../../../../../components/Loader';
import Input from '../../../../../components/Form/Input';
import Notification from '../../../../../components/Notification';
import EventWrapper from '../../../../../components/EventWrapper';
import DatePicker from '../../../../../components/Form/DatePicker';
import PayerGridAutoComplete from '../../../../../wiredComponents/PayerGridAutoComplete';

import WorkList from './Worklist';
import ClaimTable from './ClaimsTable';
import LineItemsTable from './LineItemsTable';
import PostedClaimTable from './PostedClaimsTable';

import ErrorTabs from '../ErrorTabs';

import { parsedLineItemData, parsedDecimalAmount } from '../RemittanceCommonParser';
import {
  convertToRangeString,
  getPageNumbers, getuuid, isPageRange, sortedUniq,
} from '../../../../../lib/util';
import useReduxState from '../../../../../hooks/useReduxState';

import './insurancepayment.scss';
import ErrorMessages from '../../../../../lib/errorMessages';

const columns = [
  {
    Header: 'Name',
    accessor: 'name',
  },
];

const setCurrentPath = () => {};
const InsurancePayment = ({
  onBack, labels, currentPageNo, totalNoOfPages, remittanceBatchData, setCurrentPageNo,
  isElectronicBatch, path,
}) => {
  const {
    replace, generatePath, path: currentPath, params: {
      insurancePaymentPage, remittancePaymentId, remittanceId, tabId, pageNo,
      claimId: newClaimId, id: newPatientId, financialId,
    }, params,
  } = useRedirect();
  const uuidRef = useRef(getuuid());
  const formId = path.indexOf('new') > -1 ? uuidRef.current : remittancePaymentId;
  const newFormId = path.indexOf('new') > -1 ? tabId : remittancePaymentId;

  const [
    insurancePaymentDetails,, insurancePaymentLoading,
    getInsurancePaymentDetails,
    clearInsurancePaymentDetails,
  ] = useCRUD({
    id: `${listIds.GET_REMITTANCE_PAYMENT_DETAILS}_${formId}`,
    url: apiUrls.GET_REMITTANCE_PAYMENT_DETAILS,
    type: 'read',
  });

  const [
    secondInsurancePaymentDetails,,,
    getSecondInsurancePaymentDetails,
    clearSecondInsurancePaymentDetails,
  ] = useCRUD({
    id: `${listIds.GET_REMITTANCE_PAYMENT_DETAILS}_Second_${formId}`,
    url: apiUrls.GET_REMITTANCE_PAYMENT_DETAILS,
    type: 'read',
  });

  const [
    viewEnteredItemsDetails,,
    viewEnteredItemLoading,
    getViewEnteredItemsDetails,
    clearViewEnteredItemsDetails,
  ] = useCRUD({
    id: `GET_VIEW_ENTERED_ITEMS_${formId}`,
    url: apiUrls.GET_VIEW_ENTERED_ITEMS,
    type: 'read',
  });

  const [
    amountAfterPostingResponse,,,
    getAmountAfterPostingBatch,
    clearGetAmountResponse,
  ] = useCRUD({
    id: 'GET_AMOUNT_AFTER_POSTING_BATCH',
    url: apiUrls.GET_AMOUNT_AFTER_POSTED_BATCH,
    type: 'read',
  });

  const [
    insurancePaymentLineItemResponse,,
    insurancePaymentLineItemLoading,
    saveInsurancePaymentLineItem,
    clearInsurancePaymentLineItem,
  ] = useCRUD({
    id: listIds.EDIT_REMITTANCE_FINANCIAL_INSURANCE_PAYMENT,
    url: apiUrls.EDIT_INSURANCE_PAYMENT,
    type: 'create',
  });

  const [
    insurancePaymentResponse,,,
    saveInsurancePayment,
    clearInsurancePayment,
  ] = useCRUD({
    id: `${listIds.SET_INSURANCE_PAYMENT}-${formId}`,
    url: apiUrls.SET_INSURANCE_PAYMENT,
    type: 'create',
  });

  const [
    completeCheckResponse,,
    completeCheckLoading,
    completeCheck,
    clearCompleteCheck,
  ] = useCRUD({
    id: listIds.REMITTANCE_FINANCIAL_COMPLETE_CHECK,
    url: apiUrls.REMITTANCE_FINANCIAL_COMPLETE_CHECK,
    type: 'create',
  });

  const [searchText, setSearchText] = useReduxState(`Insurance-Payment-Search-Text-${formId}`);
  const [search, setSearch] = useReduxState(`Insurance-Payment-Search-${formId}`);
  const [payerData, setPayerData] = useReduxState(`Insurance-Payer-Data-${formId}`);
  const [isWorklistModalVisible, setIsWorklistModalVisible] = useState(false);
  const [pageRange, setPageRange] = useReduxState(`Insurance-Payment-Page-Range-${formId}`, '');
  const [isPageRangeCalculate, setIsPageRangeCalculate] = useReduxState(`Insurance-Payment-Page-Range-Calculate-${formId}`, '');
  const [needToScroll, setNeedToScroll] = useReduxState(`Insurance-Payment-Need-To-Scroll-${formId}`, true);
  const [claimPageNo, setClaimPageNo] = useReduxState(`Insurance-Claim-Page-Number-${formId}`, currentPageNo);
  const [isPosted, setIsPosted] = useReduxState(`Insurance-Claim-Page-Number-${tabId}`);
  const [, setNewLineItemData] = useReduxState(`insurance-payment-lineItemData-${newClaimId}-${newPatientId}-${financialId}-${newFormId}-encounterId-${tabId}`, []);
  const [, setUpdatedLineItemsList] = useReduxState(`insurance-payment-updatedLineItemsList-${newClaimId}-${newPatientId}-${financialId}-${newFormId}-encounterId-${tabId}`, []);
  const lineItemComponentRef = useRef();
  const storePageRef = useRef();
  const [form] = AntdForm.useForm();

  const debouncedSetSearch = useMemo(() => debounce(setSearch, 1500), [setSearch]);

  const handleSearch = useCallback(({ target: { value } }) => setSearchText(value),
    [setSearchText]);

  useEffect(() => {
    const newSearchString = searchText || '';
    if (newSearchString && newSearchString.length >= 3) {
      debouncedSetSearch(newSearchString);
    } else {
      debouncedSetSearch();
    }
  }, [debouncedSetSearch, searchText]);

  const isBatchPosted = useMemo(() => remittanceBatchData?.stateMasterCode === 'BillingStatePosted', [remittanceBatchData]);

  const shouldCallAlreadyPaidAmount = useMemo(() => remittanceBatchData?.stateMasterCode === 'BillingStatePosted'
  || remittanceBatchData?.stateMasterCode === 'BillingStateOutBox' || remittanceBatchData?.stateMasterCode === 'BillingStateError', [remittanceBatchData]);

  const navigateToClaimTable = useCallback(() => {
    setNewLineItemData([]);
    setUpdatedLineItemsList([]);
    if (!isBatchPosted) {
      replace(
        generatePath(
          `${path}/searchClaim`,
          params,
        ),
      );
    }
    form.setFieldsValue({ payerId: null });
  }, [setNewLineItemData, setUpdatedLineItemsList, isBatchPosted,
    form, replace, generatePath, path, params]);

  const navigateOnCancel = useCallback(() => {
    if (currentPath?.includes('/error')) {
      replace(
        generatePath(
          currentPath,
          params,
        ),
      );
    } else {
      if (currentPath.includes('/:insurancePaymentPage')) {
        const newPath = currentPath?.includes('batchDetailsError') ? UiRoutes.claimsRemittanceErrorEditInsurance : UiRoutes.claimsRemittanceEditInsurance;
        replace(generatePath(
          newPath,
          {
            ...params,
          },
        ));
      } else {
        replace(
          generatePath(
            `${path}/searchClaim`,
            params,
          ),
        );
      }
      form.setFieldsValue({ payerId: null });
    }
  }, [currentPath, replace, generatePath, params, path, form]);

  const handleBack = useCallback(() => {
    setNewLineItemData([]);
    setUpdatedLineItemsList([]);
    form.completeReset();
    setSearchText();
    onBack();
  }, [form, onBack, setNewLineItemData, setSearchText, setUpdatedLineItemsList]);

  const navigateToPostedClaims = useCallback(() => {
    setSearchText();
    replace(
      generatePath(
        path,
        params,
      ),
    );
  }, [setSearchText, replace, generatePath, path, params]);

  const navigateBack = useCallback(() => {
    form.completeReset();
    onBack();
    replace(
      generatePath(
        UiRoutes.claimsRemittance,
        params,
      ),
    );
  }, [form, onBack, replace, generatePath, params]);

  const formValidation = useCallback(async (shouldSetError = true, totalNoOfPagesInPdf) => {
    const formData = form.getFieldsValue();
    const errors = [];
    if (!formData.checkAmount) {
      errors.push({
        name: 'checkAmount',
        value: formData.checkAmount,
        errors: ['Check Amount is required'],
      });
    }
    if (!formData.checkNumber) {
      errors.push({
        name: 'checkNumber',
        value: formData.checkNumber,
        errors: ['Check Number is required'],
      });
    }
    if (!formData.checkDate) {
      errors.push({
        name: 'checkDate',
        value: formData.checkDate,
        errors: ['Check Date is required'],
      });
    }
    if (!formData.payerId) {
      errors.push({
        name: 'payerId',
        value: formData.payerId,
        errors: ['Payer is required'],
      });
    }
    if (!formData.pageNo
       && (path === `${UiRoutes.claimsRemittanceNewInsurance}/searchClaim/:claimId/:id`
       || path === `${UiRoutes.claimsRemittanceEditInsurance}/searchClaim/:claimId/:id`
       || path === `${UiRoutes.claimsRemittanceErrorEditInsurance}/searchClaim/:claimId/:id`)
       && !isElectronicBatch
    ) {
      errors.push({
        name: 'pageNo',
        value: formData.pageNo,
        errors: ['EOB Page Number is required'],
      });
    }
    if (totalNoOfPagesInPdf && formData.pageNo) {
      let checkClaimNumber = false;
      try {
        await isPageRange(formData.pageNo, labels.get('claimPageNumber'), totalNoOfPagesInPdf).validator();
        checkClaimNumber = true;
      } catch (e) {
        checkClaimNumber = false;
      }
      if ((path === `${UiRoutes.claimsRemittanceNewInsurance}/searchClaim/:claimId/:id`
        || path === UiRoutes.claimsRemittanceEditInsurance
        || path === `${UiRoutes.claimsRemittanceEditInsurance}/searchClaim/:claimId/:id`
        || path === `${UiRoutes.claimsRemittanceErrorEditInsurance}/searchClaim/:claimId/:id`)
        && !isElectronicBatch && !checkClaimNumber
      ) {
        errors.push({
          name: 'pageNo',
          value: formData.pageNo,
          errors: ['Please enter a valid Claim Page Number '],
        });
      }
    }

    if (errors.length) {
      if (shouldSetError) {
        form.setFields(errors);
      }
      return false;
    }

    return true;
  }, [form, isElectronicBatch, labels, path]);

  const onPost = useCallback(async (
    lineItemData,
    secondaryPayer,
    claimId,
    remittancePaymentIdentifier,
    isRemittanceAddPayment,
    checkboxChecked,
  ) => {
    const isValidated = await formValidation(true, totalNoOfPages);
    if (!insurancePaymentDetails?.transactionTypeId
    && !secondInsurancePaymentDetails?.transactionTypeId) {
      Notification({ message: ErrorMessages.PLEASE_ADD_CHECK_DETAILS, success: false });
    } else if (isValidated) {
      let formData = form.getFieldsValue();
      formData = omit(formData, 'pageRange');
      formData = omit(formData, 'pageNo');
      lineItemData.map((item) => {
        // eslint-disable-next-line no-param-reassign
        item.pageNo = !isElectronicBatch ? storePageRef.current || form?.getFieldValue('pageNo') || currentPageNo : null;
        return item;
      });
      const clonedLineItemData = parsedLineItemData(lineItemData);
      saveInsurancePaymentLineItem({
        data: {
          ...formData,
          LineItems: clonedLineItemData,
          paymentId: remittancePaymentId,
          remittanceBatchId: remittanceId,
          transactionTypeId: secondInsurancePaymentDetails?.transactionTypeId
          || insurancePaymentDetails.transactionTypeId,
          existingClaimId: claimId,
          IsAnyFurtherPayerResponsibility: secondaryPayer,
          remittancePaymentIdentifier,
          isRemittanceAddPayment,
          pageNo: storePageRef.current || form.getFieldValue('pageNo'),
          isCheckUpdate: true,
          checkDate: moment(formData?.checkDate).format('YYYY-MM-DD 00:00:00'),
          workListId: clonedLineItemData?.[0]?.workListId,
          isCrossOverToSecondary: checkboxChecked,
        },
      });
      setIsPosted(true);
      setNeedToScroll(false);
    }
  }, [formValidation, insurancePaymentDetails, secondInsurancePaymentDetails, form,
    saveInsurancePaymentLineItem, remittancePaymentId, remittanceId, setNeedToScroll,
    isElectronicBatch, currentPageNo, totalNoOfPages, setIsPosted]);

  const autoSaveForm = useCallback(async () => {
    const isValidated = await formValidation(path === UiRoutes.claimsRemittanceEditInsurance
      || path === UiRoutes.claimsRemittanceErrorEditInsurance, totalNoOfPages);
    const isFormValid = !form.getFieldsError().some((item) => item?.errors?.length > 0);
    if (isValidated && isFormValid) {
      const formData = form.getFieldsValue();
      formData.pageNo = `${form.getFieldValue('pageNo') || currentPageNo || 1}`;
      saveInsurancePayment({
        data: {
          ...formData,
          checkDate: moment(formData?.checkDate).format('YYYY-MM-DD 00:00:00'),
          FinancePaymentId: parseInt(remittancePaymentId, 10) || 0,
          remittancePaymentId,
          PaymentSource: isElectronicBatch ? 'Remitted' : 'OverTheCounter',
          PaymentMethod: isElectronicBatch ? 'Electronic' : 'Manual',
          remittanceBatchId: remittanceId,
          Source: 'InsurancePayment',
          batchnumber: remittanceBatchData?.batchNumber,
          batchIdentifier: remittanceBatchData?.batchIdentifier,
        },
      });
    }
  }, [formValidation, path, totalNoOfPages, form, currentPageNo, saveInsurancePayment,
    remittancePaymentId, isElectronicBatch, remittanceId, remittanceBatchData]);

  const handleCompleteCheck = useCallback(() => {
    completeCheck({
      data: {
        paymentId: remittancePaymentId,
        source: 'InsurancePayment',
        remittancebatchid: remittanceId,
        IsBatchAlreadyPosted: shouldCallAlreadyPaidAmount,
      },
    });
  }, [completeCheck, remittanceId, remittancePaymentId, shouldCallAlreadyPaidAmount]);

  const toggleWorklistModal = useCallback(async () => {
    if (isBatchPosted) { return; }
    if (!isWorklistModalVisible) {
      const isValidated = await formValidation();
      if (isValidated) {
        setIsWorklistModalVisible(!isWorklistModalVisible);
      }
    } else {
      setIsWorklistModalVisible(!isWorklistModalVisible);
    }
  },
  [isBatchPosted, formValidation, isWorklistModalVisible]);

  useEffect(() => {
    Events.on('GET_AMOUNT_AFTER_POSTING_BATCH', 'GET_AMOUNT_AFTER_POSTING_BATCH', () => {
      getAmountAfterPostingBatch({
        PaymentId: remittancePaymentId,
        remittanceBatchId: remittanceId,
      });
    });
    return () => { Events.remove('GET_AMOUNT_AFTER_POSTING_BATCH', 'GET_AMOUNT_AFTER_POSTING_BATCH'); };
  }, []);

  useEffect(() => {
    Events.on(`GET_VIEW_ENTERED_ITEMS_${remittancePaymentId}`, `GET_VIEW_ENTERED_ITEMS_${remittancePaymentId}`, () => getViewEnteredItemsDetails({ PaymentId: remittancePaymentId }));
    return () => {
      Events.remove(`GET_VIEW_ENTERED_ITEMS_${remittancePaymentId}`, `GET_VIEW_ENTERED_ITEMS_${remittancePaymentId}`);
    };
  }, [getViewEnteredItemsDetails, remittancePaymentId]);

  useEffect(() => {
    if (completeCheckResponse) {
      Notification({ message: successMessages.INSURANCE_PAYMENT_CHECK_COMPLETE, success: true });
      clearCompleteCheck();
      Events.trigger(`refetch-remittance-payment-details${remittanceId}`);
      Events.trigger('refetchBatchDetails');
      navigateBack();
    }
  }, [completeCheckResponse]);

  useEffect(() => () => {
    clearSecondInsurancePaymentDetails(true);
    clearInsurancePaymentDetails(true);
    clearGetAmountResponse(true);
    clearViewEnteredItemsDetails(true);
  }, []);

  useEffect(() => {
    if (path === UiRoutes.claimsRemittanceEditInsurance
      || path === UiRoutes.claimsRemittanceErrorEditInsurance) {
      getInsurancePaymentDetails({}, `/${remittancePaymentId}`);
      getViewEnteredItemsDetails({ PaymentId: remittancePaymentId });
      getAmountAfterPostingBatch({
        PaymentId: remittancePaymentId,
        remittanceBatchId: remittanceId,
      });
    }
  }, [remittancePaymentId]);

  useEffect(() => {
    Events.on(`refetch-finance-view-entered-item-details${remittancePaymentId}`, `refetch-finance-view-entered-item-details${remittancePaymentId}`, () => getViewEnteredItemsDetails({ PaymentId: remittancePaymentId }));
    return () => {
      Events.remove(`refetch-finance-view-entered-item-details${remittancePaymentId}`, `refetch-finance-view-entered-item-details${remittancePaymentId}`);
    };
  }, [getViewEnteredItemsDetails, remittancePaymentId]);

  const debouncedAutosave = useCallback(debounce(autoSaveForm, 1000),
    [remittancePaymentId, totalNoOfPages]);

  useEffect(() => {
    Events.on('insurance-claim-page-number', 'insurance-claim-page-number', ({ pageNo: onPage, shouldSave = false }) => {
      if ((`${claimPageNo}`?.includes('-') || `${claimPageNo}`?.includes(',')) && !shouldSave) {
        storePageRef.current = onPage;
        return null;
      }
      form.setFieldsValue({ pageNo: onPage });
      if (remittancePaymentId && remittancePaymentId !== '0') {
        debouncedAutosave();
      }
      return null;
    });
    return () => {
      Events.remove('insurance-claim-page-number', 'insurance-claim-page-number');
    };
  }, [claimPageNo, form, remittancePaymentId, totalNoOfPages]);

  useEffect(() => {
    Events.on(`refetch-insurance-payment-details-${remittancePaymentId}`, `refetch-insurance-payment-details-${remittancePaymentId}`, () => {
      getInsurancePaymentDetails({}, `/${remittancePaymentId}`);
    });
    return () => Events.remove(`refetch-insurance-payment-details-${remittancePaymentId}`, `refetch-insurance-payment-details-${remittancePaymentId}`);
  }, [remittancePaymentId]);

  const handlePageRangeChangeScroll = useCallback((pageRangeToScroll) => {
    setClaimPageNo(pageRangeToScroll || 1);
    Events.trigger('PDF_VIEWER', { pageNo: parseInt(pageRangeToScroll || 1, 10) });
    Events.trigger('PDF_VIEWER_PAGE_NO', { pageNo: parseInt(pageRangeToScroll || 1, 10), shouldTriggerClaimPage: false });
  }, [setClaimPageNo]);

  const autoSaveWhenLineItemPosted = useCallback(() => {
    if (isPosted && ((!isElectronicBatch && isPageRangeCalculate)
    || isElectronicBatch)) {
      autoSaveForm();
      setIsPosted(false);
      setIsPageRangeCalculate(false);
    }
  }, [autoSaveForm, isElectronicBatch, isPageRangeCalculate,
    isPosted, setIsPageRangeCalculate, setIsPosted]);

  useEffect(() => {
    if (secondInsurancePaymentDetails) {
      clearInsurancePaymentDetails(true);
      const { financeDetails } = secondInsurancePaymentDetails;
      const { viewEnteredItems } = viewEnteredItemsDetails || {};
      if (!!pageRange && viewEnteredItems) {
        const initialPageRange = pageRange === 'Pages Not Found' ? '' : pageRange;
        form.setFieldsValue({
          pageRange: initialPageRange,
        });
        if (needToScroll) {
          setTimeout(handlePageRangeChangeScroll(parseInt(financeDetails?.pageNo, 10)), 0);
        } else {
          setNeedToScroll(true);
        }
        if (isBatchPosted && pageRange !== financeDetails?.pageRange) {
          setPageRange(financeDetails?.pageRange || 1);
          form.setFieldsValue({
            pageRange: financeDetails?.pageRange || 1,
          });
        }
        if (!isBatchPosted) {
          setPageRange('');
          autoSaveForm();
        }
      } else if (isBatchPosted && pageRange !== financeDetails?.pageRange) {
        setPageRange(financeDetails?.pageRange || 1);
        form.setFieldsValue({
          pageRange: financeDetails?.pageRange || 1,
        });
      }
    }
  }, [secondInsurancePaymentDetails, pageRange, viewEnteredItemsDetails]);

  useEffect(() => {
    if (insurancePaymentDetails) {
      const { financeDetails } = insurancePaymentDetails;
      const { viewEnteredItems } = viewEnteredItemsDetails || {};
      const formData = {
        checkNumber: financeDetails?.checkNumber,
        checkAmount: (financeDetails?.checkAmount || 0).toFixed(2),
        checkDate: moment(financeDetails?.checkDate),
        payerId: financeDetails?.payerId,
        pageNo: currentPageNo,
      };
      setClaimPageNo(currentPageNo);
      if (!!pageRange && viewEnteredItems) {
        const initialPageRange = pageRange === 'Pages Not Found' ? '' : pageRange;
        form.setFieldsValue({
          ...formData,
          pageRange: initialPageRange,
        });
        autoSaveWhenLineItemPosted();
        if (needToScroll) {
          setTimeout(handlePageRangeChangeScroll(parseInt(currentPageNo, 10)), 0);
        } else {
          setNeedToScroll(true);
        }
        if (isBatchPosted && pageRange !== financeDetails?.pageRange) {
          setPageRange(financeDetails?.pageRange || 1);
          form.setFieldsValue({
            pageRange: financeDetails?.pageRange || 1,
          });
          autoSaveWhenLineItemPosted();
        }
        if (!isBatchPosted) {
          setPageRange('');
        }
      } else {
        if (isBatchPosted && pageRange !== financeDetails?.pageRange) {
          setPageRange(financeDetails?.pageRange || 1);
          form.setFieldsValue({
            pageRange: financeDetails?.pageRange || 1,
          });
        }
        form.setFieldsValue(formData);
        autoSaveWhenLineItemPosted();
      }
    }
  }, [insurancePaymentDetails, pageRange, viewEnteredItemsDetails]);

  useEffect(() => {
    if (viewEnteredItemsDetails) {
      const { viewEnteredItems } = viewEnteredItemsDetails;
      if (!isElectronicBatch && !isBatchPosted) {
        setPageRange(convertToRangeString(sortedUniq(getPageNumbers(viewEnteredItems))));
        setIsPageRangeCalculate(isPosted);
      }
      if (!viewEnteredItems?.length && !currentPath.includes('/searchClaim/:claimId/:id')) navigateToClaimTable();
    }
  }, [viewEnteredItemsDetails, isElectronicBatch, isBatchPosted]);

  useEffect(() => {
    if (insurancePaymentLineItemResponse && !Array.isArray(insurancePaymentLineItemResponse)) {
      storePageRef.current = undefined;
      Notification({
        message: successMessages.INSURANCE_PAYMENT_SAVED_SUCCESSFULLY,
        success: true,
      });
      form.setFieldsValue({
        pageNo: parseInt(currentPageNo, 10),
      });
      setClaimPageNo(parseInt(currentPageNo, 10) || 1);
      Events.trigger('PDF_VIEWER', { pageNo: parseInt(currentPageNo || 1, 10) });
      Events.trigger('PDF_VIEWER_PAGE_NO', { pageNo: parseInt(currentPageNo || 1, 10), shouldTriggerClaimPage: false });
      setSearchText();
      clearInsurancePaymentLineItem();
      lineItemComponentRef.current?.clearLineItemData();
      clearInsurancePaymentDetails(true);
      getSecondInsurancePaymentDetails({}, `/${remittancePaymentId}`);
      getViewEnteredItemsDetails({ PaymentId: remittancePaymentId });
      Events.trigger('GET_AMOUNT_AFTER_POSTING_BATCH');
      Events.trigger('refetchBatchDetails');
      let newPath = currentPath?.includes('batchDetailsError') ? UiRoutes.claimsRemittanceErrorEditInsurance : UiRoutes.claimsRemittanceEditInsurance;
      if (currentPath?.includes('/error')) {
        newPath = currentPath;
      }
      replace(generatePath(
        newPath,
        {
          ...params,
          remittancePaymentId: insurancePaymentLineItemResponse?.financeId
            ?? insurancePaymentLineItemResponse?.patientFinance,
          insurancePaymentPage: insurancePaymentPage ?? 'addPaymentDetails',
        },
      ));
    }
  }, [insurancePaymentLineItemResponse, form, currentPageNo]);

  useEffect(() => {
    if (insurancePaymentResponse && !Array.isArray(insurancePaymentResponse)) {
      Notification({ message: successMessages.PAYMENT_SAVED_SUCCESSFULLY, success: true });
      getSecondInsurancePaymentDetails({}, `/${insurancePaymentResponse?.financeId}`);
      Events.trigger(`refetch-remittance-payment-details${remittanceId}`);
      if (!params?.remittancePaymentId || params?.remittancePaymentId === '0') {
        replace(generatePath(
          currentPath?.includes('batchDetailsError') ? UiRoutes.claimsRemittanceErrorEditInsurance : currentPath,
          {
            ...params,
            remittancePaymentId: insurancePaymentResponse?.financeId,
            insurancePaymentPage: insurancePaymentPage ?? 'addPaymentDetails',
          },
        ));
      }
    }
    clearInsurancePayment();
  }, [insurancePaymentResponse]);

  const insuranceAmountDetails = useMemo(() => {
    const totalAmount = {
      claimAmount: 0, totalPostedAmount: 0, interestAmount: 0, accountAmount: 0,
    };
    const { totalPosted, viewEnteredItems } = viewEnteredItemsDetails || {};
    viewEnteredItems?.forEach((item) => {
      const isClaimItem = item?.applied && item?.patientId;
      if (isClaimItem) {
        totalAmount.claimAmount += item?.applied || 0;
        totalAmount.interestAmount += item?.interest || 0;
      } else {
        totalAmount.accountAmount += item?.balance || 0;
      }
    });
    totalAmount.totalPostedAmount = totalPosted || 0;
    return totalAmount;
  }, [viewEnteredItemsDetails]);

  return (
    <div>
      {(insurancePaymentLineItemLoading
        || completeCheckLoading || insurancePaymentLoading) && <Loader />}
      <div className="insurance-header">
        <div
          onClick={handleBack}
          className="back-arrow head-back"
          role="presentation"
        >
          {labels.get('insurancePayment')}
        </div>
        <div className="head-text">
          <div className="col">
            <div className="key">
              {labels.get('check')}
              :
            </div>
            <div className="value">
              {' '}
              {secondInsurancePaymentDetails?.financeDetails?.checkNumber
              || insurancePaymentDetails?.financeDetails?.checkNumber}
            </div>
          </div>
          <div className="col">
            <div className="key">
              {labels.get('amount')}
              :
              {' '}
            </div>
            <div className="value">
              $
              {secondInsurancePaymentDetails?.amount?.toFixed(2)
              || insurancePaymentDetails?.amount?.toFixed(2) || 0?.toFixed(2)}
            </div>
          </div>
          <div className="col">
            <div className="key">
              {labels.get('posted')}
              :
              {' '}
            </div>
            <div className="value">
              $
              {parsedDecimalAmount(shouldCallAlreadyPaidAmount
                ? (amountAfterPostingResponse?.applied || 0)
                        + (insuranceAmountDetails?.accountAmount || 0)
                        + (amountAfterPostingResponse?.interest || 0)
                : (insuranceAmountDetails?.claimAmount || 0)
                + (insuranceAmountDetails?.accountAmount || 0)
                + (insuranceAmountDetails?.interestAmount || 0))}
            </div>
          </div>
        </div>
      </div>
      <div className="insurance-pay-detail">
        <div className="insurance-field">
          <Form
            form={form}
            formId={`insurance_payment-${formId}`}
            name={`insurance_payment-${formId}`}
            listId={`insurance_payment-${formId}`}
            key={tabId}
            getUrl="dummy"
            tabId={tabId}
            initialData={{
              pageNo: !isElectronicBatch ? pageNo || currentPageNo : null,
            }}
          >
            <Input
              label={labels.get('checkAmount')}
              labelSpan="10"
              inputSpan="14"
              name="checkAmount"
              numberWithDecimal
              maxValueLength={11}
              onBlur={autoSaveForm}
              placeholder="Enter Check Amount"
              required
              disabled={isBatchPosted}
            />
            <Input
              label={labels.get('checkNumber')}
              labelSpan="10"
              inputSpan="14"
              maxValueLength={50}
              name="checkNumber"
              onBlur={autoSaveForm}
              placeholder="Enter Check Number"
              required
              disabled={isBatchPosted}
            />
            <DatePicker
              label={labels.get('checkDate')}
              labelSpan="10"
              inputSpan="14"
              name="checkDate"
              onChange={autoSaveForm}
              required
              disabled={isBatchPosted}
            />
            <Input
              label={labels.get('checkPageNumber')}
              labelSpan="10"
              inputSpan="14"
              name="pageRange"
              disabled
              placeholder="Enter Check Page Number"
            />
            <Input
              label={labels.get('claimPageNumber')}
              labelSpan="10"
              inputSpan="14"
              name="pageNo"
              onBlur={autoSaveForm}
              onChange={(e) => handlePageRangeChangeScroll(e.target.value)}
              rules={[
                ({ getFieldValue }) => isPageRange(getFieldValue('pageNo'), labels.get('claimPageNumber'), totalNoOfPages),
              ]}
              placeholder="Enter Claim Page Number"
              required={!isElectronicBatch}
            />
            <PayerGridAutoComplete
              id="payerId"
              name="payerId"
              label={labels.get('payer')}
              nameAccessor="name"
              valueAccessor="insurancePayerId"
              columns={columns}
              labelSpan="10"
              inputSpan="14"
              onSelect={autoSaveForm}
              required
              disabled={isBatchPosted}
              params={{
                SortBy: 'name',
                SortOrder: 'asc',
                PageIndex: 0,
                PageSize: 100000,
                IncludeInactive: true,
                IsSearchingFromPayerAgingOverrides: true,
              }}
              initialValue={(insurancePaymentDetails?.financeDetails?.payerId
                || payerData?.payerId) && {
                name: insurancePaymentDetails?.financeDetails?.payerName || payerData?.payerName,
                value: insurancePaymentDetails?.financeDetails?.payerId || payerData?.payerId,
              }}
              dropdownMatchSelectWidth
              header={false}
            />
            <Route
              exact
              path={[
                `${UiRoutes.claimsRemittanceNewInsurance}/searchClaim/:claimId/:id`,
                `${UiRoutes.claimsRemittanceEditInsurance}/searchClaim/:claimId/:id`,
                `${UiRoutes.claimsRemittanceErrorEditInsurance}/searchClaim/:claimId/:id`,
              ]}
            >
              <div style={{ display: 'none' }}>
                <Input label="" name="pageNo" />
              </div>
            </Route>
          </Form>
        </div>
        <div className="insurance-table">
          <div className="table-in app-table">
            <table>
              <tbody>
                <tr>
                  <td>
                    {labels.get('claims')}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {parsedDecimalAmount(
                      (shouldCallAlreadyPaidAmount
                        ? amountAfterPostingResponse?.applied
                        : insuranceAmountDetails?.claimAmount) || 0,
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    {labels.get('account')}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {parsedDecimalAmount(insuranceAmountDetails?.accountAmount || 0)}
                  </td>
                </tr>
                <tr>
                  <td>
                    {labels.get('interest')}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {parsedDecimalAmount(
                      (shouldCallAlreadyPaidAmount
                        ? amountAfterPostingResponse?.interest
                        : insuranceAmountDetails?.interestAmount) || 0,
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    {labels.get('net')}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {parsedDecimalAmount(
                      shouldCallAlreadyPaidAmount
                        ? (amountAfterPostingResponse?.applied || 0)
                        + (insuranceAmountDetails?.accountAmount || 0)
                        + (amountAfterPostingResponse?.interest || 0)
                        : (insuranceAmountDetails?.claimAmount || 0)
                    + (insuranceAmountDetails?.accountAmount || 0)
                    + (insuranceAmountDetails?.interestAmount || 0),
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="action-text">
            <Route
              exact
              path={[
                UiRoutes.claimsRemittanceEditInsurance,
                UiRoutes.claimsRemittanceErrorEditInsurance,
                UiRoutes.claimsRemittanceNewInsurance,
                `${UiRoutes.claimsRemittanceErrorEditInsurance}/error`,
                `${UiRoutes.claimsRemittanceNewInsurance}/searchClaim/:claimId/:id`,
                `${UiRoutes.claimsRemittanceEditInsurance}/searchClaim/:claimId/:id`,
                `${UiRoutes.claimsRemittanceErrorEditInsurance}/searchClaim/:claimId/:id`,
              ]}
            >
              <p onClick={!isBatchPosted && navigateToClaimTable} role="presentation" className={`${isBatchPosted && 'cursor-not-allowed'}`}>{labels.get('addPayment')}</p>
            </Route>
            <Route
              exact
              path={[
                `${UiRoutes.claimsRemittanceEditInsurance}/searchClaim`,
                UiRoutes.claimsRemittanceEditInsurance,
                UiRoutes.claimsRemittanceNewInsurance,
                `${UiRoutes.claimsRemittanceNewInsurance}/searchClaim`,
                `${UiRoutes.claimsRemittanceEditInsurance}/searchClaim/:claimId/:id`,
                UiRoutes.claimsRemittanceErrorEditInsurance,
                `${UiRoutes.claimsRemittanceErrorEditInsurance}/searchClaim`,
                `${UiRoutes.claimsRemittanceErrorEditInsurance}/searchClaim/:claimId/:id`,
                `${UiRoutes.claimsRemittanceErrorEditInsurance}/error`,
              ]}
            >
              <EventWrapper type="p" onClick={toggleWorklistModal} className={`${isBatchPosted && 'cursor-not-allowed'}`}>{labels.get('addToWorklist')}</EventWrapper>
            </Route>
          </div>
        </div>
      </div>
      <div className="search-table-wrap">
        <Route
          exact
          path={[
            UiRoutes.claimsRemittanceEditInsurance,
            UiRoutes.claimsRemittanceNewInsurance,
            `${UiRoutes.claimsRemittanceEditInsurance}${UiRoutes.postClaimTablePopUp}`,
            `${UiRoutes.claimsRemittanceEditInsurance}${UiRoutes.postClaimTablePopUpWithSearchText}`,
            `${UiRoutes.claimsRemittanceEditInsurance}${UiRoutes.postClaimTablePopUpWithSearchTextWithErrorId}`,
            `${UiRoutes.claimsRemittanceEditInsurance}${UiRoutes.postClaimTablePopUpWithSelectedClaim}`,
            `${UiRoutes.claimsRemittanceEditInsurance}${UiRoutes.postClaimTablePopUpWithSelectedClaimWithErrorId}`,
            `${UiRoutes.claimsRemittanceNewInsurance}${UiRoutes.postClaimTablePopUp}`,
            `${UiRoutes.claimsRemittanceNewInsurance}${UiRoutes.postClaimTablePopUpWithSearchText}`,
            `${UiRoutes.claimsRemittanceNewInsurance}${UiRoutes.postClaimTablePopUpWithSearchTextWithErrorId}`,
            `${UiRoutes.claimsRemittanceNewInsurance}${UiRoutes.postClaimTablePopUpWithSelectedClaim}`,
            `${UiRoutes.claimsRemittanceNewInsurance}${UiRoutes.postClaimTablePopUpWithSelectedClaimWithErrorId}`,
          ]}
        >
          <PostedClaimTable
            labels={labels}
            searchText={search}
            setCurrentPath={setCurrentPath}
            transactionTypeId={secondInsurancePaymentDetails?.transactionTypeId
              || insurancePaymentDetails?.transactionTypeId}
            data={viewEnteredItemsDetails}
            loading={viewEnteredItemLoading}
            formDetails={form?.getFieldsValue()}
            form={form}
            isInsurancePayment
            remittanceBatchData={remittanceBatchData}
            setCurrentPageNo={setCurrentPageNo}
            currentPageNo={currentPageNo}
            setNeedToScroll={setNeedToScroll}
            setClaimPageNo={setClaimPageNo}
          />
        </Route>
        <Route
          exact
          path={[
            UiRoutes.claimsRemittanceErrorEditInsurance,
            `${UiRoutes.claimsRemittanceErrorEditInsurance}${UiRoutes.postClaimTablePopUp}`,
            `${UiRoutes.claimsRemittanceErrorEditInsurance}${UiRoutes.postClaimTablePopUpWithSearchText}`,
            `${UiRoutes.claimsRemittanceErrorEditInsurance}${UiRoutes.postClaimTablePopUpWithSearchTextWithErrorId}`,
            `${UiRoutes.claimsRemittanceErrorEditInsurance}${UiRoutes.postClaimTablePopUpWithSelectedClaim}`,
            `${UiRoutes.claimsRemittanceErrorEditInsurance}${UiRoutes.postClaimTablePopUpWithSelectedClaimWithErrorId}`,
          ]}
        >
          <ErrorTabs
            labels={labels}
            setCurrentPath={setCurrentPath}
            transactionTypeId={secondInsurancePaymentDetails?.transactionTypeId
              || insurancePaymentDetails?.transactionTypeId}
            remittanceBatchData={remittanceBatchData}
            formDetails={form?.getFieldsValue()}
            currentPageNo={currentPageNo}
          />
          <PostedClaimTable
            labels={labels}
            searchText={search}
            setCurrentPath={setCurrentPath}
            transactionTypeId={secondInsurancePaymentDetails?.transactionTypeId
              || insurancePaymentDetails?.transactionTypeId}
            data={viewEnteredItemsDetails}
            loading={viewEnteredItemLoading}
            formDetails={form?.getFieldsValue()}
            form={form}
            isInsurancePayment
            remittanceBatchData={remittanceBatchData}
            setCurrentPageNo={setCurrentPageNo}
            currentPageNo={currentPageNo}
            setNeedToScroll={setNeedToScroll}
            setClaimPageNo={setClaimPageNo}
          />
        </Route>
        <Route
          exact
          path={[
            `${UiRoutes.claimsRemittanceNewInsurance}/searchClaim`,
          ]}
        >
          <div className="insurance-payment-search mr-bt-16">
            <Search placeholder="Search Patient" value={searchText} onChange={handleSearch} />
          </div>
          <ClaimTable
            labels={labels}
            searchText={search}
            setCurrentPath={setCurrentPath}
            insurancePayerId={form.getFieldValue('payerId')}
            formId={formId}
            addBillingStageNoResponse
          />
        </Route>
        <Route
          exact
          path={[
            `${UiRoutes.claimsRemittanceEditInsurance}/searchClaim`,
          ]}
        >
          <div className="insurance-payment-search mr-bt-16">
            <Search placeholder="Search Patient" value={searchText} onChange={handleSearch} />
            <Button onClick={navigateToPostedClaims} className="btn btn-success sm-btn">View Entered Items</Button>
          </div>
          <ClaimTable
            labels={labels}
            searchText={search}
            setCurrentPath={setCurrentPath}
            insurancePayerId={secondInsurancePaymentDetails?.financeDetails?.payerId || insurancePaymentDetails?.financeDetails?.payerId || form.getFieldValue('payerId')}
            formId={formId}
            addBillingStageNoResponse
          />
        </Route>
        <Route
          exact
          path={[
            `${UiRoutes.claimsRemittanceErrorEditInsurance}/searchClaim`,
          ]}
        >
          <ErrorTabs
            labels={labels}
            setCurrentPath={setCurrentPath}
            transactionTypeId={secondInsurancePaymentDetails?.transactionTypeId
              || insurancePaymentDetails?.transactionTypeId}
            remittanceBatchData={remittanceBatchData}
            currentPageNo={currentPageNo}
          />
          <div className="insurance-payment-search mr-bt-16">
            <Search placeholder="Search Patient" value={searchText} onChange={handleSearch} />
            <Button onClick={navigateToPostedClaims} className="btn btn-success sm-btn">View Entered Items</Button>
          </div>
          <ClaimTable
            labels={labels}
            searchText={search}
            setCurrentPath={setCurrentPath}
            insurancePayerId={secondInsurancePaymentDetails?.financeDetails?.payerId || insurancePaymentDetails?.financeDetails?.payerId || form.getFieldValue('payerId')}
            formId={formId}
            addBillingStageNoResponse
          />
        </Route>
        <Route
          exact
          path={[
            `${UiRoutes.claimsRemittanceEditInsurance}/searchClaim/:claimId/:id/:identifier`,
            `${UiRoutes.claimsRemittanceErrorEditInsurance}/searchClaim/:claimId/:id/:identifier`,
            `${UiRoutes.claimsRemittanceErrorEditInsurance}/error/searchClaim/:claimId/:id/:identifier`,
            `${UiRoutes.claimsRemittanceNewInsurance}/searchClaim/:claimId/:id/:encounterId/:isRemittanceAddPayment`,
            `${UiRoutes.claimsRemittanceEditInsurance}/searchClaim/:claimId/:id/:encounterId/:isRemittanceAddPayment`,
            `${UiRoutes.claimsRemittanceErrorEditInsurance}/searchClaim/:claimId/:id/:encounterId/:isRemittanceAddPayment`,
            `${UiRoutes.claimsRemittanceNewInsurance}/searchClaim/:claimId/:id/:encounterId`,
            `${UiRoutes.claimsRemittanceEditInsurance}/searchClaim/:claimId/:id/:encounterId`,
            `${UiRoutes.claimsRemittanceErrorEditInsurance}/searchClaim/:claimId/:id/:encounterId`,
            `${UiRoutes.claimsRemittanceNewInsurance}/searchClaim/:claimId/:id`,
            `${UiRoutes.claimsRemittanceEditInsurance}/searchClaim/:claimId/:id`,
            `${UiRoutes.claimsRemittanceErrorEditInsurance}/searchClaim/:claimId/:id`,
          ]}
        >
          <div className="flex justify-content-flex-end mr-bt-16">
            <Button onClick={navigateToPostedClaims} className="btn btn-success sm-btn">View Entered Items</Button>
          </div>
          <LineItemsTable
            labels={labels}
            onCancel={navigateOnCancel}
            onPost={onPost}
            setCurrentPath={setCurrentPath}
            form={form}
            remittanceId={remittanceId}
            isRemittancePayment
            showActionIcons
            autoSaveForm={autoSaveForm}
            currentPageNo={form.getFieldValue('pageNo')}
            isElectronicBatch={isElectronicBatch}
            setPayerData={setPayerData}
            doNotFetchLineItemsData
            ref={lineItemComponentRef}
          />
        </Route>
        <Route
          exact
          path={[
            `${UiRoutes.claimsRemittanceErrorEditInsurance}/error`,
            `${UiRoutes.claimsRemittanceErrorEditInsurance}/error${UiRoutes.postClaimTablePopUp}`,
            `${UiRoutes.claimsRemittanceErrorEditInsurance}/error${UiRoutes.postClaimTablePopUpWithSearchText}`,
            `${UiRoutes.claimsRemittanceErrorEditInsurance}/error${UiRoutes.postClaimTablePopUpWithSearchTextWithErrorId}`,
            `${UiRoutes.claimsRemittanceErrorEditInsurance}/error${UiRoutes.postClaimTablePopUpWithSelectedClaim}`,
            `${UiRoutes.claimsRemittanceErrorEditInsurance}/error${UiRoutes.postClaimTablePopUpWithSelectedClaimWithErrorId}`,
          ]}
        >
          <ErrorTabs
            labels={labels}
            setCurrentPath={setCurrentPath}
            defaultTab={params?.errorTab}
            formDetails={form?.getFieldsValue()}
            transactionTypeId={secondInsurancePaymentDetails?.transactionTypeId
              || insurancePaymentDetails?.transactionTypeId}
            remittanceBatchData={remittanceBatchData}
            currentPageNo={currentPageNo}
            setNeedToScroll={setNeedToScroll}
            setClaimPageNo={setClaimPageNo}
            form={form}
            viewEnteredItemsDetails={viewEnteredItemsDetails}
            viewEnteredItemLoading={viewEnteredItemLoading}
          />
        </Route>
        <Route
          exact
          path={[
            UiRoutes.claimsRemittanceEditInsurance,
            UiRoutes.claimsRemittanceNewInsurance,
            UiRoutes.claimsRemittanceErrorEditInsurance,
            `${UiRoutes.claimsRemittanceErrorEditInsurance}/error`,
          ]}
        >
          <div className="flex mr-top-16">
            <button type="button" className="btn btn-success mr-rt-16" onClick={handleCompleteCheck}>{labels.get('completeCheck')}</button>
            <button type="button" className="btn btn-primary" onClick={navigateBack}>{labels.get('cancel')}</button>
          </div>
        </Route>
      </div>
      <WorkList
        labels={labels}
        path={path}
        isVisible={isWorklistModalVisible}
        toggle={toggleWorklistModal}
        formData={form?.getFieldsValue()}
        totalNoOfPages={totalNoOfPages}
        navigateToPostedClaims={navigateToPostedClaims}
        isWorkListWithClaimId={currentPath?.split('searchClaim/')?.[1]?.length}
        isElectronicBatch={isElectronicBatch}
        setNeedToScroll={setNeedToScroll}
      />
    </div>
  );
};

export default withLabel(InsurancePayment, labelPaths.REMITTANCE_NEW_INSURANCE);
