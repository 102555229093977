import React, { useState } from 'react';
import RadioGroup from '../RadioGroup';

const TabContainer = ({
  tabs,
  defaultActiveTab,
  className = 'comment-log-section',
  ...props
}) => {
  const [activeTab, setActiveTab] = useState(defaultActiveTab || tabs[0]?.value);

  const tabList = tabs.map(({ value, label }) => ({
    value,
    label,
  }));

  const handleTabSwitch = ({ target: { value } }) => {
    setActiveTab(value);
  };

  const getCurrentTabComponent = () => {
    const currentTab = tabs.find((tab) => tab.value === activeTab);
    if (!currentTab?.component) return null;
    const Component = currentTab.component;
    return <Component {...props} />;
  };

  return (
    <div className="myTab">
      <RadioGroup
        tabList={tabList}
        onChange={handleTabSwitch}
        value={activeTab}
      />
      <div className={className}>
        {getCurrentTabComponent()}
      </div>
    </div>
  );
};

export default TabContainer;
