/* eslint-disable no-nested-ternary */
import React from 'react';

import { UiRoutes } from '../../../lib/constants';

import Link from '../../../components/SavedNavigationBar/Link';
import TimeCell from '../../../components/Cells/TimeCell';
import EncounterEvaluationStatus from '../../../wiredComponents/AddNewEncounter/Components/RulesEngine/EncounterEvaluationStatus';

const columns = (labels) => [
  {
    Header: labels.get('tableColumns.ruleEvaluation'),
    accessor: 'ruleEvaluationStatus',
    Cell: ({
      row: {
        original: {
          ruleEvaluationStatus,
          patientFirstName,
          patientId, encounterId,
          id,
          patientMiddleName,
          patientLastName,
        },
      },
    }) => {
      let patientName = patientFirstName;
      if (patientMiddleName) patientName += ` ${patientMiddleName}`;
      if (patientLastName) patientName += ` ${patientLastName}`;
      return (
        <Link
          to={UiRoutes.editBillingEncounterWithTabId}
          params={{
            id: patientId,
            encounterId: encounterId || id,
            data: { name: patientName },
          }}
        >
          <EncounterEvaluationStatus ruleEvaluationStatus={ruleEvaluationStatus} />
        </Link>
      );
    },
    className: 'text-align-center',
    fixWidth: 50,
  },
  {
    Header: labels.get('tableColumns.patientName'),
    accessor: 'patientFirstName',
    Cell: ({ row: { original } }) => {
      let patientName = original?.patientFirstName;
      if (original?.patientMiddleName) patientName += ` ${original?.patientMiddleName}`;
      if (original?.patientLastName) patientName += ` ${original?.patientLastName}`;
      return (
        <span>{patientName}</span>
      );
    },
    sort: true,
  },
  {
    Header: labels.get('tableColumns.dos'),
    accessor: 'dateofservice',
    Cell: ({ row: { original: { dateOfService } } }) => <TimeCell value={dateOfService?.split('T')?.[0] || ''} />,
    sort: true,
  },
  {
    Header: labels.get('tableColumns.encounterStatus'),
    accessor: 'status',
  },
  {
    Header: labels.get('tableColumns.visitType'),
    accessor: 'visitType',
  },
  {
    Header: labels.get('tableColumns.insuranceName'),
    accessor: 'insurancePayerName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.policyType'),
    accessor: 'policyType',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.provider'),
    accessor: 'provider',
    Cell: ({ row: { original } }) => {
      let providerName = original?.providerFirstName;
      if (original?.providerMiddleName) providerName += ` ${original?.providerMiddleName}`;
      if (original?.providerLastName) providerName += ` ${original?.providerLastName}`;
      return <span>{providerName}</span>;
    },
  },
  {
    Header: labels.get('tableColumns.location'),
    accessor: 'locationName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.unbilled'),
    accessor: 'unbilled',
    sort: true,
    Cell: ({ row: { original: { unbilled } } }) => (
      (unbilled || 0).toFixed(2)
    ),
    className: 'text-align-right',
  },
  {
    Header: labels.get('tableColumns.totalAmount'),
    accessor: 'totalValue',
    sort: true,
    Cell: ({ row: { original: { totalValue } } }) => (
      (totalValue || 0).toFixed(2)
    ),
    className: 'text-align-right',
  },
  {
    Header: 'Signed',
    accessor: 'patientEncounterStatus',
    Cell: ({ row: { original } }) => {
      let patientEncounterStatus = original?.patientEncounterStatus;
      if (original?.patientEncounterStatus === 'Signed') patientEncounterStatus = 'Yes';
      else patientEncounterStatus = 'No';
      return <span>{patientEncounterStatus}</span>;
    },
  },
];

export default columns;
