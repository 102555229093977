import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { Menu } from 'antd';
import { Route } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import classNames from 'classnames';

import { apiUrls } from '../../../../../api/constants';
import withReduxManager from '../../../../../hoc/withReduxManager';
import useTabLink from '../../../../../hooks/useTabLink';
import useCRUD from '../../../../../hooks/useCRUD';
import useRedirect from '../../../../../hooks/useRedirect';
import { UiRoutes } from '../../../../../lib/constants';

import Button from '../../../../../components/Button';

import holdReadyColumns from './Components/holdReadyColumns';
import outboxColumns from './Components/outboxColumns';
import waystarColumn from './Components/waystarColumns';

import Task from '../../../../../wiredComponents/Modal/Tasks';
import Notification from '../../../../../components/Notification';
import WidgetLoader from '../../../../../components/WidgetLoader';
import FilterComponents from '../../../../../components/FilterComponents';
import SuccessMessages from '../../../../../lib/successMessages';
import ErrorMessages from '../../../../../lib/errorMessages';
import FilterManager from '../../../../../components/FilterManager';
import TableWrapper from '../../../../../components/Table/TableWrapper';
import withQueryPagination from '../../../../../hoc/withQuery/withQueryPagination';

const initialSort = [{ id: 'patientName', desc: true }];

const FilterCollectionSearch = FilterComponents([
  {
    type: 'search',
    filterProps: {
      placeholder: 'Search by Patient Name',
      name: 'SearchText',
      id: 'statements_filter_search',
      style: {
        width: '300px',
      },
    },
  },
]);
const TypeFilters = [
  {
    placeholder: 'Ready',
    name: 'ready',
    id: 'ready',
  },
  {
    placeholder: 'On-Hold',
    name: 'onHold',
    id: 'on_hold',
  },
  {
    placeholder: 'Outbox',
    name: 'outbox',
    id: 'outbox',
  },
  {
    placeholder: 'Waystar',
    name: 'waystar',
    id: 'waystar',
  },
];

const columns = {
  ready: holdReadyColumns,
  onHold: holdReadyColumns,
  outbox: outboxColumns,
  waystar: waystarColumn,
};

const status = {
  ready: 'StatementReady',
  onHold: 'StatementHold',
  outbox: 'StatementOutBox',
  waystar: 'StatementSubmitted',
};

const RenderTaskModal = ({ toggleModal, labels }) => {
  const { params } = useRedirect();
  return (
    <Task visible labels={labels} toggleModal={toggleModal} patientId={params?.patientId} />
  );
};

const BillingEncounterTable = ({ labels, ...props }) => {
  const [activeTab, setActiveTab] = useState('ready');
  const [reFetchTable, setReFetch] = useState(() => { /* This is intentional */ });
  const [selectedRows, setSelectedRows] = useState([]);
  const viewPanel = useRef();

  const {
    params, replace, generatePath, push,
  } = useRedirect();

  const [holdResponse, , holdLoading, holdStatement, clearHoldResponse] = useCRUD({
    id: apiUrls.HOLD_STATEMENTS,
    url: apiUrls.HOLD_STATEMENTS,
    type: 'update',
  });
  const [assembleResponse,,
    assembleLoading,
    assembleStatement,
    clearAssembleResponse] = useCRUD({
    id: apiUrls.ASSEMBLE_STATEMENTS,
    url: apiUrls.ASSEMBLE_STATEMENTS,
    type: 'update',
  });

  const [submitResponse,,
    submitLoading,
    submitStatement,
    clearSubmitResponse] = useCRUD({
    id: apiUrls.SUBMIT_STATEMENTS,
    url: apiUrls.SUBMIT_STATEMENTS,
    type: 'update',
  });

  const [statementHtml,,
    statementHtmlLoading,
    getStatementHtml,
    clearStatementHtmlResponse] = useCRUD({
    id: 'get-claims-statement-html',
    url: apiUrls.GET_STATEMENT_HTML,
    type: 'create',
  });

  useEffect(() => {
    if (holdResponse) {
      Notification({
        message: activeTab === 'ready' ? SuccessMessages.STATEMENT_STATUS_HOLD
          : SuccessMessages.STATEMENT_STATUS_UN_HOLD,
        success: true,
      });
      clearHoldResponse();
      reFetchTable();
    }
  }, [holdResponse]);

  useEffect(() => {
    if (assembleResponse) {
      Notification({ message: SuccessMessages.STATEMENT_STATUS_ASSEMBLE, success: true });
      clearAssembleResponse();
      reFetchTable();
      setSelectedRows([]);
    }
  }, [assembleResponse]);

  useEffect(() => {
    if (submitResponse) {
      Notification({ message: SuccessMessages.STATEMENT_STATUS_SUBMIT, success: true });
      clearSubmitResponse();
      reFetchTable();
      setSelectedRows([]);
    }
  }, [submitResponse]);

  const onChangeTab = useCallback((e) => {
    const { target: { name } } = e;
    setActiveTab(name);
    replace(generatePath(UiRoutes.statementClaimsFilters, { ...params, claimFilter: name }));
  }, [generatePath, params, replace]);

  const { navigate } = useTabLink({
    to: UiRoutes.editPatientWithTabId,
    finalRedirectPath: (path) => path.replace('dashboard', 'demographic'),
    // Added above function to maintain tab
    // uniqueness from patient list as well because patient list
    // redirect use to dashboard and this page requires redirection on demographic so that why
    // I am doing this hack. (Ramesh Bansal)
    params: { tab: 'dashboard', data: { name: '' } },
  });

  const changeStatementStatus = useCallback((method, statementStatus) => {
    if (selectedRows?.length) {
      method({
        statementIds: selectedRows.map(({ original }) => original?.statementId),
        status: statementStatus,
      });
    } else {
      Notification({ message: ErrorMessages.PLEASE_SELECT_AT_LEAST_ONE_TABLE_ITEM });
    }
  }, [selectedRows]);

  const onAddTask = useCallback((row) => () => {
    push(generatePath(UiRoutes.statementClaimsFiltersAddTask,
      { ...params, patientId: row?.original?.patientId }));
  }, [generatePath, params, push]);

  const onEditPatient = useCallback((row) => () => {
    navigate({
      id: row?.original?.patientId,
      data: {
        name: row?.original?.patientName,
      },
    });
  }, [navigate]);

  const handleHoldUnHold = useCallback((row) => () => {
    holdStatement({
      status: row?.original?.statusName?.toLowerCase()?.trim() === 'ready' ? 'StatementHold' : 'StatementReady',
      statementIds: [row?.original?.statementId],
    });
  }, [holdStatement]);

  const handleHtmlPrint = useReactToPrint({
    content: () => viewPanel.current,
    copyStyles: false,
  });

  useEffect(() => {
    if (statementHtml) {
      viewPanel.current.innerHTML = statementHtml || '';
      handleHtmlPrint();
      clearStatementHtmlResponse();
    }
  }, [statementHtml]);

  const getStatementHtmlFromSever = useCallback((original, name) => {
    if (!name && !selectedRows?.length) {
      Notification({ message: ErrorMessages.PLEASE_SELECT_AT_LEAST_ONE_TABLE_ITEM });
    } else {
      getStatementHtml({
        data: {
          statementIds: name ? [original?.statementId]
            : selectedRows?.map(({ original: selectedOriginal }) => selectedOriginal?.statementId),
          patientIds: name ? [original?.patientId]
            : selectedRows?.map(({ original: selectedOriginal }) => selectedOriginal?.patientId),
          isPreview: true,
        },
      });
    }
  }, [getStatementHtml, selectedRows]);

  const onPreviewClick = useCallback(({ original }, name) => () => {
    getStatementHtmlFromSever(original, name);
  }, [getStatementHtmlFromSever]);

  const rightClickMenu = useCallback((row) => () => (
    <Menu className="profile-menu-container">
      <Menu.Item key="Edit Patient">
        <span role="presentation" onClick={handleHoldUnHold(row)}>{activeTab === 'ready' ? 'Hold Statement for this Patient' : 'Unhold Statement for this Patient'}</span>
      </Menu.Item>
      <Menu.Item key="view preview">
        <span role="presentation" onClick={onPreviewClick(row, 'previewButton')}>Preview Statement</span>
      </Menu.Item>
      <Menu.Item key="Delete claim">
        <span role="presentation" onClick={onEditPatient(row)}>Edit Patient</span>
      </Menu.Item>
      <Menu.Item key="Delete claim">
        <span role="presentation" onClick={onAddTask(row)}>Add Tasks</span>
      </Menu.Item>
    </Menu>
  ), [handleHoldUnHold, onAddTask, onEditPatient, onPreviewClick]);

  const activeColumn = useMemo(() => columns[activeTab], [activeTab]);

  const closeModal = useCallback(() => {
    replace(generatePath(UiRoutes.statementClaimsFilters, params));
  }, [generatePath, params, replace]);

  const onMarkAsSubmit = useCallback(() => {
    changeStatementStatus(submitStatement, 'StatementSubmitted');
  }, [changeStatementStatus, submitStatement]);

  const handleClick = useCallback(() => {
    if (activeTab === 'ready') {
      changeStatementStatus(assembleStatement, 'StatementOutBox');
    } else {
      getStatementHtmlFromSever();
    }
  }, [activeTab, assembleStatement, changeStatementStatus, getStatementHtmlFromSever]);

  const CustomBillingTable = useMemo(() => withReduxManager({
    listId: `${apiUrls.GET_STATEMENTS}-${activeTab}`,
  })(withQueryPagination({
    url: apiUrls.GET_STATEMENTS,
    listId: `${apiUrls.GET_STATEMENTS}-${activeTab}`,
  })()),
  [activeTab, statementHtml]);

  return (
    <div className="posi-relative">
      {(holdLoading
       || assembleLoading
       || submitLoading
       || statementHtmlLoading)
       && <WidgetLoader />}
      <CustomBillingTable
        columns={activeColumn && activeColumn(labels)}
        activeTab={activeTab}
        initialSort={initialSort}
        filters={{
          StatementStatus: status[activeTab] || null,
          IncludeSentToWayStar: false,
        }}
        noDataText="Statement data not found"
        showRowSelection
        rightClickMenu={activeTab !== 'outbox' && activeTab !== 'waystar' && rightClickMenu}
        setSelectedRow={setSelectedRows}
        checkedRowIds={selectedRows}
        footer
        pageSize={50}
        isPagination
        {...props}
      >
        {({
          Component, initialFilters, onFiltersChange, reFetch,
        }) => (
          <FilterManager initialFilters={initialFilters} onChange={onFiltersChange}>
            {({ onFilterChange, filters }) => (
              <>
                <div className="heading-area flex">
                  { TypeFilters.map((value, index) => (
                    <button
                      type="button"
                      name={value.name}
                      className={classNames(
                        'btn btn-primary sm-btn filter-button', activeTab === value.name ? 'active' : '',
                      )}
                      key={index + 1}
                      onClick={onChangeTab}
                    >
                      {value.placeholder}
                    </button>
                  ))}
                </div>
                <div className="table-filters">
                  <FilterCollectionSearch
                    onFilterChange={onFilterChange}
                    filters={filters}
                    allowClear
                  />
                  { activeTab !== 'onHold' && (
                    <div className="flex justify-content-flex-end mr-bt-10">
                      {activeTab !== 'waystar' && (
                      <Button
                        className="btn btn-success sm-btn"
                        id="remittance_add"
                        data-testid="assemble-patients"
                        onClick={handleClick}
                      >
                        {activeTab === 'ready'
                          ? labels.get('buttons.assembleStatements')
                          : `${labels.get('buttons.print')} (${selectedRows.length} selected)`}
                      </Button>
                      )}
                      {activeTab === 'outbox' && (
                      <Button className="btn btn-success sm-btn mr-lt-10" id="remittance_add" onClick={onMarkAsSubmit}>
                        {labels.get('buttons.markAsSubmitted')}
                      </Button>
                      )}
                    </div>
                  )}
                </div>
                <TableWrapper reFetch={reFetch} Component={Component} setReFetch={setReFetch} />
              </>
            )}
          </FilterManager>
        )}
      </CustomBillingTable>
      <Route path={UiRoutes.statementClaimsFiltersAddTask}>
        <RenderTaskModal labels={labels} toggleModal={closeModal} />
      </Route>
      <div className="display-none" ref={viewPanel} />
    </div>
  );
};

export default BillingEncounterTable;
